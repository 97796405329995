import React from 'react'
import {Helmet} from "react-helmet";
import { mapEdgesToNodes } from '../lib/helpers'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PageTransition from 'gatsby-plugin-page-transitions';

import { responsiveTitle1 } from '../components/typography.module.css'

const SchedulePage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout>
      <PageTransition>
      <SEO title='Schedule' />
      <Helmet><script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js" integrity="sha512-+NqPlbbtM1QqiK8ZAo4Yrj2c4lNQoGv8P79DPtKzj++l5jnN39rHA/xsqn8zE9l0uSoxaCdrOgFs6yjyfbBxSg==" crossorigin="anonymous" defer={false}></script> 
        <script src="https://www.momoyoga.com/schedule-plugin/v2/js/schedule.js" crossorigin="anonymous" defer={false}/></Helmet>
      <Container>
      <div className="inner-container">
        <h1 data-sal="slide-up" data-sal-delay="10" data-sal-duration="600" data-sal-easing="ease" className={responsiveTitle1}>Schedule</h1>
        <div className="momoyoga-schedule" data-momo-schedule="https://www.momoyoga.com/rock-yoga/"></div>
        </div>
      </Container>
      </PageTransition>
    </Layout>
  )
}

export default SchedulePage
